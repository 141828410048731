import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NarrowContent from "../components/visualContainers/narrowContent"
import PageTitle from "../components/contentComponents/pageTitle"
import {H2, H3, P} from "../components/contentComponents/text"
import Button, { Buttons } from "../components/contentComponents/button"
import styled from "styled-components"
import WideImage from "../components/contentComponents/wideImage"
import MaterialContainer from "../components/visualContainers/materialContainer"
import mainImage from "../images/slice1.jpg"
import computerImg from "../images/computer.png"
import chooseIcon from "../images/choose_icon.svg"
import payIcon from "../images/card_payment_icon.svg"
import recurringPaymentIcon from "../images/recurring_payment_icon.svg"
import whiteCardsImage from "../images/card_logos_white.png"
import Wall from "../components/visualComponents/wall"
import NumberedRect from "../components/visualContainers/numberedRect"
import BulletList from "../components/contentComponents/bulletList"
import Disc from "../components/visualComponents/disc"
import Cross from "../components/visualComponents/cross"

const SlicePage = (props) => {
  const t = props.pageContext.pageData
  const { site, locale, pageName, fullPath } = props.pageContext

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={t.pageTitle} lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <NarrowContent>
        <PageTitle mobileWall={true} title={t.hero.title}>
          <PageTitleDisc material="lightBlueOpaque" width={64} position="right: -100px; top: 0;" />
          <Wall id={"wall1"} hideMobile={true} height={50} position="left: -70px; bottom: 10px;"/>
          <Wall id={"wall2"} hideMobile={true} height={50} position="left: -40px; bottom: 10px;"/>
        </PageTitle>
      </NarrowContent>
      <WideImage image={mainImage}>
        <BubbleAnimator>
          <BubbleContainer material="greenGradient" circle={true} width={300} zIndex={6}>
            <div className="center-vertical">
              <BubbleSmallTitle>
                {t.hero.bubbleTopTitle}
              </BubbleSmallTitle>
              <BubbleBox width={93}>
                <BubbleSmallP>{t.hero.bubbleTopBoxTitle}</BubbleSmallP>
                <BubbleBigNumber>{t.hero.bubbleTopBoxPrice}</BubbleBigNumber>
              </BubbleBox>
              <BubbleDivider />
              <BubbleSmallTitle>
                {t.hero.bubbleBottomTitle}
              </BubbleSmallTitle>
              <BubbleBoxRow>
                <BubbleBox width={69}>
                  <BubbleBigNumber small={true}>{t.hero.bubbleBottomBox1Price}</BubbleBigNumber>
                  <BubbleSmallP>{t.hero.bubbleBottomBox1Title}</BubbleSmallP>
                </BubbleBox>
                <BubbleBox width={69}>
                  <BubbleBigNumber small={true}>{t.hero.bubbleBottomBox2Price}</BubbleBigNumber>
                  <BubbleSmallP>{t.hero.bubbleBottomBox2Title}</BubbleSmallP>
                </BubbleBox>
                <BubbleBox width={69}>
                  <BubbleBigNumber small={true}>{t.hero.bubbleBottomBox3Price}</BubbleBigNumber>
                  <BubbleSmallP>{t.hero.bubbleBottomBox3Title}</BubbleSmallP>
                </BubbleBox>
              </BubbleBoxRow>
            </div>

          </BubbleContainer>
        </BubbleAnimator>
        <Wall id={"wall3"} hideMobile={true} height={50} position="left: -45px; top: 0;"/>
        <Wall id={"wall4"} hideMobile={true} height={50} position="right: -75px; top: 0;"/>
        <Wall id={"wall5"} hideMobile={true} height={50} position="right: -45px; top: 0;"/>
        <Wall id={"wall6"} hideMobile={true} height={90} position="right: -50px; top: 50%;"/>
        <Wall id={"wall13"} hideDesktop={true} height={48} position="left: 10px; top: -290px;"/>
        <Wall id={"wall14"} hideDesktop={true} height={48} position="left: 30px; top: -290px;"/>
        <Disc material="lightBlueOpaque" hideMobile={true} width={64} position="left: -30px; bottom: 50px;"/>
      </WideImage>
      <NarrowContent>
        <GlassTextPanel mb={90} material="transparent" zIndex={7} className="motion" dataYFrom={40} dataYTo={-40}>
          <H3 strong={true} white={true}>
            {t.hero.bodyText}
          </H3>
        </GlassTextPanel>
        <Buttons className="center" mt={0} mb={24} >
          <Button title={t.hero.buttonTitle} linkTo={site.pages.about.defaultPath}/>
        </Buttons>
        <div style={{position:"relative"}}>
          <Wall id={"wall7"} hideMobile={true} height={50} position="left: -45px; top: 0;"/>
          <Wall id={"wall8"} hideMobile={true} height={50} position="left: -75px; top: 150px;"/>
          <Wall id={"wall9"} hideMobile={true} height={50} position="left: -45px; top: 150px;"/>
          <Wall id={"wall10"} hideMobile={true} height={64} position="right: -50px; top: 0;"/>
          <Disc material="lightBlueOpaque" hideMobile={true} zIndex={6} width={64} position="left: -80px; bottom: 0;"/>
          <BlackPanel mt={80} material="almostBlack" className="motion" dataYFrom={80} dataYTo={-80}>
            <ContainerLeft>
              <SpacedRow>
                <PanelTexts>
                  <H2 color="#D2FF98" white={true}>{t.how.title}</H2>
                  <P white={true} addMargins={true}>
                    {t.how.body}
                  </P>
                </PanelTexts>
              </SpacedRow>
              <Squares>
                <NumberedRect
                  color="#D2FF98"
                  white={true}
                  material="brightBlueBorder"
                  nr={1}
                  text={t.how.box1}
                  icon={chooseIcon}
                />
                <NumberedRect
                  color="#D2FF98"
                  white={true}
                  material="brightBlueBorder"
                  nr={2}
                  text={t.how.box2}
                  icon={payIcon}
                >
                  <div className="center">
                    <WhiteCardsImage src={whiteCardsImage} alt="" />
                  </div>
                </NumberedRect>
                <NumberedRect
                  color="#D2FF98"
                  white={true}
                  material="brightBlueBorder"
                  nr={3}
                  text={t.how.box3}
                  icon={recurringPaymentIcon}
                />
              </Squares>
            </ContainerLeft>
          </BlackPanel>
          <BlackDiscWithImage circle={true} width={260} material="almostBlack">
            <DiscImage src={computerImg} alt="" />
          </BlackDiscWithImage>
        </div>

        <div style={{position: "relative"}}>
          <Wall id={"wall11"} hideMobile={true} height={75} position="right: -70px; top: 0;"/>
          <Wall id={"wall12"} hideMobile={true} height={75} position="right: -70px; top: 120px;"/>
          <Cross width={100} hideMobile={true} position="left: -120px; top: 180px;"/>
          <Wall id={"wall15"} hideDesktop={true} height={64} position="right: 0px; top: -140px;"/>
          <Cross width={60} hideDesktop={true} position="left: 0px; top: -180px;"/>
          <LightPanel mb={80} material="lightBlueOpaque" className="motion" dataYFrom={80} dataYTo={-80}>
            <ContainerLeft>
              <H2>{t.benefits.title}</H2>
              <P strong={true} addMargins={true}>{t.benefits.bodyTop}</P>
              <BulletList items={[
                t.benefits.bullet1,
                t.benefits.bullet2,
                t.benefits.bullet3,
                t.benefits.bullet4
              ]} />
              <P addMargins={true}>{t.benefits.bodyBottom}</P>
            </ContainerLeft>
          </LightPanel>
          <ColorDisc padding={32} circle={true} width={200} material="greenGradient" noShadow={true}>
            <div className="center-vertical">
              <WithPlus>{t.benefits.discPercent}%</WithPlus>
              <P small={true} addMargins={true}>{t.benefits.discBody}</P>
            </div>
          </ColorDisc>
        </div>
      </NarrowContent>
    </Layout>
  );
}

export default SlicePage


const ContainerLeft = styled.div`
  width: 100%;
  
  @media only screen and (min-width: 1024px) {
    width: calc(100% - 150px);
  }
`

const BlackPanel = styled(MaterialContainer)`
  @media only screen and (max-width: 767px) {
    margin-top: 280px;
    padding-top: 56px;
  }
`

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const PanelTexts = styled.div`
  flex: 0 0 auto;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    width: calc(100% - 320px);
  }
  
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`

const Squares = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin-top: 40px;
  }
`

const BlackDiscWithImage = styled(MaterialContainer)`
  position: absolute;
  top: -260px;
  left: 50%;
  transform: translateX(-50%);
  margin: 24px auto;
  flex: 0 0 auto;
  
  @media only screen and (min-width: 768px) {
    top: -60px;
    right: 32px;
    left: unset;
    transform: unset;
    margin: 0;
    margin-left: 24px;
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    right: 0;
    top: 44%;
    transform: translate(50%, -50%);
  }
`

const DiscImage = styled.img`
  transform: scale(0.5);
`

const LightPanel = styled(MaterialContainer)`
  margin-top: 200px;
  padding-top: 60px;
  margin-bottom: 40px;
  
  @media only screen and (min-width: 768px) {
    margin-top: 220px;
    padding-top: 60px;
    margin-bottom: 80px;
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: 80px;
    padding-top: 48px;
  }
`

const ColorDisc = styled(MaterialContainer)`
  position: absolute;
  right: 50%;
  top: 40px;
  text-align: center;
  transform: translate(50%, -100%);
  
  @media only screen and (min-width: 1024px) {
    right: 40px;
    top: -40px;
    transform: unset;
  }
`

const WithPlus = styled.div`
  position: relative;
  display: flex;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0;
  margin-bottom: -22px;
  text-align: center;
  padding-bottom: 8px;
  
  &::before {
    content: "+";
    display: inline-block;
    height: 100%;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 70px;
    line-height: 48px;
    text-align: center;
    color: #000000;
    vertical-align: middle;
  }
`

const BubbleAnimator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`

const BubbleContainer = styled(MaterialContainer)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  justify-content: flex-start;
  
  .circle-content {
    justify-content: flex-start;
    padding-top: 32px;
  }
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: -140px;
    left: 24px;
    transform: unset;
    
    & > div {
      padding: 24px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 110px;
    top: unset;
    left: 30px;
    transform: unset;
    border-radius: 50%;
    width: 300px;
    
    & > div {
      padding: 48px;
    }
  }
`

const BubbleSmallTitle = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  margin-bottom: 8px;
`

const BubbleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: 0 0 auto;
  width: ${props => props.width}px;
  margin-right: 8px;
  
  &:last-of-type {
    margin-right: 0;
  }
`

const BubbleSmallP = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 0;
`

const BubbleBigNumber = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.small ? "17px" : "25px"};
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 0;
`

const BubbleDivider = styled.div`
  width: 160px;
  height: 0;
  border: 1px solid #B0ECFB;
  margin: 12px 0;
`

const BubbleBoxRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  
`

const GlassTextPanel = styled(MaterialContainer)`
  margin-top: -80px;
  margin-bottom: 90px;
  
  @media only screen and (max-width: 767px) {
    margin-top: -40px;
    margin-bottom: 54px;
    
    & > h3 {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: -140px;
  }
`

const PageTitleDisc = styled(Disc)`
  right: -20px;
  top: 0;
  
  @media only screen and (min-width: 768px) {
    right: -100px;
    top: 0;
  }
`

const WhiteCardsImage = styled.img`
  margin: 16px 0;
`